export const ROLES = [
  {
    id: 10,
    name: "Кандидат",
  },
  {
    id: 20,
    name: "Фрилансер",
  },
  {
    id: 30,
    name: "Куратор Indigolab",
  },
  {
    id: 50,
    name: "Сотрудник отдела продаж",
  },
  {
    id: 80,
    name: "Владелец или представитель магазина",
  },
];

export const isAdmin = (roleId) => roleId === 100;
export const isCurator = (roleId) => roleId === 30;
export const isManager = (roleId) => roleId === 20;
export const isQuality = (roleId) => [95, 96].includes(roleId);
